<!-- footer -->
<footer class="footer site-footer">
    <div class="container" style=" padding-top: 24px;">
        <!-- <div class="footer-top skin-bg-color">
            <div class="container">
                <div class="row justify-content-center mr-0">
                    <div class="col-12 col-md-10 col-lg-8 res_newsletter">
                        <form class="card card-sm">
                            <div class="card-body row no-gutters align-items-center">
                               
                                <div class="col-md-7 col-sm-12" style="margin-bottom: 10px;">
                                    <input id="email" [(ngModel)]="EmailID" class="form-control"
                                        placeholder="Enter Your Email" name="email" type="email" value=""
                                        aria-required="true" />
                                </div>
                               
                                <div class="col-md-5  col-sm-12">
                                    <button (click)="onSubrForNewsLetter()" style="width: 100%;"
                                        class="btn btn-lg subrBtn src_btn" type="submit">
                                        {{currentLabelsObj["Sign Up For Newsletter"]}}   
                                    </button>
                                </div>
                              
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="footerlogo mb-4">
                    <img class="" src="assets/images/footer_logo.png" alt="" />
                </div>
                <p class="mb-0 footer_p" style="text-align: justify;">
                    {{currentLabelsObj["IAContent"]}}
                </p>
                <div class="social-links-wrapper">
                    <ul class="social-icons">
                       <li>
                            <a href="https://www.facebook.com/IndiaAlliance" target="_blank" class="tooltip-top"
                                data-toggle="tooltip" data-placement="top" data-tooltip="Facebook"><i
                                    class="fa fa-facebook"></i></a>
		      </li>
                        <li>
                            <a href="https://twitter.com/India_Alliance" target="_blank" class="tooltip-top"
                                        data-toggle="tooltip" data-placement="top" data-tooltip="Twitter">
                                        <img src="assets/images/twitter_x.svg" alt="Twitter X Logo" width="25"
                                            height="25" style="margin-top: -5px; margin-left: -1px;">
                                            <!-- <img src="assets/images/twitter_x.svg" class="img-responsive" /> -->
                                        <!-- <i class="fa-brands fa-x-twitter"></i> -->
                                    </a>
                                
                        </li>
                        <li>
                            <a href="https://www.youtube.com/user/WTDBTIndiaAlliance" target="_blank"
                                class="tooltip-top" data-toggle="tooltip" data-placement="top" data-tooltip="Youtube"><i
                                    class="fa fa-youtube"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/indiaalliance" target="_blank"
                                class="tooltip-top" data-toggle="tooltip" data-placement="top"
                                data-tooltip="LinkedIn"><i class="fa fa-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 mt-sm-30">
                <h6 class="footer-widget-title"> {{currentLabelsObj["India Alliance"]}} </h6>
                <ul class="list-unstyled footer-link-list">
                    <li><a routerLink="/disclaimer">{{currentLabelsObj["Disclaimer"]}}</a></li>
                    <li><a routerLink="/ip-policy">{{currentLabelsObj["IP Policy"]}} </a></li>
                    <li><a routerLink="/privacy">{{currentLabelsObj["Privacy Policy"]}} </a></li>
                    <!-- <li><a routerLink="/contact"> Help</a></li> -->
                    <li><a routerLink="/career">{{currentLabelsObj["Careers"]}} </a></li>
                    
                     
                </ul>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 mt-sm-30">
                <h6 class="footer-widget-title">{{currentLabelsObj["Quick Links"]}}</h6>
                <ul class="list-unstyled footer-link-list">
                    <li><a href="https://wellcomeopenresearch.org/gateways/ia/about-this-gateway" target="_blank">Wellcome Open Research</a></li>
                    <li><a href="/news">{{currentLabelsObj["Newsletter"]}} </a></li>
                    <li><a routerLink="/news">{{currentLabelsObj["News"]}} </a></li>
                    <li><a routerLink="/applicants/faq-fellowship">{{currentLabelsObj["FAQs"]}} </a></li>
                    <!---addwed new link covid 19 section--- -->
                    <li><a href="https://indiaalliance.org/news/india-alliance-covid-nineteen-resource-hub">COVID-19 Resource Hub</a></li> 
                </ul>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 address-box mt-md-30">
                <h6 class="footer-widget-title">{{currentLabelsObj["Get In Touch"]}}</h6>
                <div class="d-flex">
                    <i class="optico-icon-location-pin"></i>
                    <p style="text-align: justify;">
                        <b>{{currentLabelsObj["Hyderabad Office"]}} : </b> {{currentLabelsObj["iaContext"]}}
                        <!-- <br/>
                        <b> Delhi Office: </b> DBT/Wellcome Trust India Alliance, 526, DLF Tower A, Jasola District
                        Centre Behind Apollo Hospital, New Delhi - 110025 -->
                    </p>
                </div>
                <div class="d-flex">
                    <i class="optico-icon-mobile"></i>
                    <p>{{currentLabelsObj["Hyderabad Office"]}}: +91 40 4018 9445 / 6 / 7</p>
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <div class="row">
                <div class="col-sm-12">
                    {{currentLabelsObj["Copyright"]}} © 2021 <a routerLink="/">{{currentLabelsObj["India Alliance"]}} </a>.{{currentLabelsObj["All rights reserved"]}} .
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Search Box Start Here -->
<div class="ts-search-overlay">
    <div class="ts-icon-close"></div>
    <div class="ts-search-outer">
        <div class="ts-search-logo">
            <img alt="optico" src="images/logo-white.png" />
        </div>
        <form class="ts-site-searchform">
            <input type="search" class="form-control field searchform-s" name="s"
                placeholder="Type Word Then Press Enter" />
            <button type="submit"><span class="optico-icon-search"></span></button>
        </form>
    </div>
</div>
<!-- Search Box End Here -->
